@import "~antd/lib/style/themes/default.less";
@import "~antd/dist/antd.less";

:root {
  /* Colors: */
  --color-bg-main: #ffffff;
  --color-bg-footer: #f5f5f5;
  --detail: #dddddd;
  --color-1-text: #838383;
  --color-2-text: #555555;
  --color-1: #0d68b0;
  --color-2: #26a79a;
  --color-3: #f05f40;

  /* Heights */
  --height-footer: 165px;
  --height-header: 145px;
}

body {
  color: var(--color-1-text);
  font-size: 18px;
  letter-spacing: 0px;
}

.container {
  width: 1170px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.text-xl {
  font-size: 35px;
}

.text-lg {
  font-size: 28px;
}

.text-md {
  font-size: 18px;
}

.text-sm {
  font-size: 16px;
}

.text-xs {
  font-size: 14px;
}

.text-bold {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.text-italic {
  font-style: italic;
}

form div:last-child {
  margin-bottom: 0;
}

h1 {
  font-size: 28px;
  color: var(--color-1);
  font-weight: bold;
}
h2 {
  font-size: 26px;
  color: var(--color-1-text);
}

hr {
  border: 0;
  border-top: 3px solid var(--color-3);
  max-width: 50px;
  margin-top: 1em;
  margin-bottom: 1em;
}

.ant-steps-item-icon {
  font-size: 14px !important;
}

.label-sm label {
  font-size: 16px;
}

@primary-color: #0d68b0;@error-color: #26a79a;@font-size-base: 18px;